



.overall-container {
display: grid;
   place-items: center;
   /* centers both horizontally and vertically */
   height: 10rem;
   /* full height of the viewport */
   /* optional: background color */
   text-align: center;
   /* centers text inside the content */
}

@media only screen and (max-width: 414px) {
   .overall-container {
        margin: -0rem;      
}
}



/*@media only screen and (orientation: landscape) and (max-width: 768px) {
   .overall-container {
      margin-right: -30rem;
   }
} */


.container {
   background-color: #581845;
      display: grid;
      place-items: center;
      justify-content: center;
}

/*@media only screen and (max-width: 414px) {
   .container {
      visibility: visible;
      display: grid;
      place-items: center;
      width: 100%
   }
}
*/

.middle-words{
   color:#FFC300;
   font-style: oblique;
}

.skills-container{
display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 20px;
   margin-top: -20.9px;
   margin-bottom: 10px;
   padding: 10px;
   border-radius: 10px;
} 

@media only screen and (max-width: 414px) {
   .skills-container {
      visibility: visible;
      display: grid;
      width: 10em;
      gap: 20px;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
      margin-left: 4.4rem;   
      }
   }

.skills-card {
   border-radius: 3.5px;
   overflow: hidden;
   box-shadow: 0 2px 4px #A9A9A9;
   width: 100px;
   height: 40px;
   border: 3.5px solid #A9A9A9;   
}

@media only screen and (max-width: 414px) {
   .skills-card {
      visibility: visible;
      width: 6.5rem;
      height: 1.9rem;
      border-radius: 0.4rem;
      border: 0.1rem solid #A9A9A9;
      box-shadow: -0 1px 1px white;
      margin-top: -15px;
      margin-left: -0.6rem;
   }
}

.image {
   max-width: 3.50rem;
   height: auto;
   justify-content: center;
   place-content: center;
   margin-bottom: 0px;
   padding: -40px;
   margin: 10px;
   border-radius: 100px;
   margin-left: 1.3rem;
}

.background {
   background-color: #5D3FD3;
   color: #A9A9A9;
   object-fit: cover;
   padding: 50px;
   margin: -5px;
}

@media only screen and (max-width: 414px) {
   .background {
      visibility: visible;
      width: 15rem;
      height: 10rem;
      
   }
}


.project-image {
   width: 100%;  
      height: 178px;  
      object-fit: cover;
}

@media only screen and (max-width: 414px) {
   .project-image {
      visibility: visible;
      width: 100%;
      height: 13.5rem;
      object-fit: cover;
   }
}

.html-image {
   max-width: 5.5rem;
      height: auto;
      justify-content: center;
      place-content: center;
      margin-bottom: -10px;
      padding: -30px;
      margin: 5px;
      border-radius: 100px;
      margin-left: 0.4rem;
      background-color: #EDEADE;
   }



      @media only screen and (max-width: 414px) {
         .name {
            visibility: visible;
            font-size: 0.70rem;
            padding: 5.5px;
            font-weight: bolder;
            margin-top: 2.5rem; 
         }
      }


.title {
   font-size: 0.7rem;
   font-weight: bolder;
   margin-top: -2.5rem;
   font-family: Arial, Helvetica, sans-serif;
}

.title-commerce {
   font-size: 0.7rem;
   margin-top: -2.5rem;
      font-weight: bolder;
      margin-right: -0.5rem;
      font-family: Arial, Helvetica, sans-serif;
}


      @media only screen and (max-width: 414px) {
         .title-commerce {
            visibility: visible;
            font-size: 0.7rem;
            margin-left: -1.5rem;
            }
   }

     .title-news {
      font-size: 0.7rem;
         margin-top: -2.5rem;
         font-weight: bolder;
         margin-left: -0.4rem;
         font-family: Arial, Helvetica, sans-serif;
     }

        @media only screen and (max-width: 414px) {
           .title-news {
              visibility: visible;
              font-size: 0.7rem;
              margin-left: -1rem;
           }
        }

   .title-banking {
      font-size: 0.7rem;
         margin-top: -2.5rem;
         font-weight: bolder;
         margin-left: -0.4rem;
         font-family: Arial, Helvetica, sans-serif;
   }

      @media only screen and (max-width: 414px) {
         .title-banking {
            visibility: visible;
            font-size: 0.7rem;
            margin-left: -0.5rem;
         }
      }
      .title-rental{
         font-size: 0.7rem;
            margin-top: -2.5rem;
            font-weight: bolder;
            margin-left: -0.4rem;
            font-family: Arial, Helvetica, sans-serif;
      }
      
      @media only screen and (max-width: 414px) {
         .title-rental {
            visibility: visible;
            font-size: 0.7rem;
            margin-left: -0.5rem;
         }
      }

            .title-food {
               font-size: 0.7rem;
               margin-top: -2.5rem;
               font-weight: bolder;
               margin-left: -0.4rem;
               font-family: Arial, Helvetica, sans-serif;
                  display: grid;
            }

          @media only screen and (max-width: 414px) {
          .title-food {
         visibility: visible;
          font-size: 0.7rem;
          margin-left: -0.1rem;
            }
             }

  .title-e {
 font-size: 0.7rem;
 margin-top: -2.5rem;
 font-weight: bolder;
 margin-left: -0.4rem;
 font-family: Arial, Helvetica, sans-serif;
  display: grid;
 }

              @media only screen and (max-width: 414px) {
                 .title-e {
                    visibility: visible;
                    font-size: 0.7rem;
                    margin-left: -0.4rem;
                 }
              }




   .title{
      font-size: 0.7rem;
         font-weight: bolder;
         margin-top: -2.5rem;
         font-family: Arial, Helvetica, sans-serif;
   }

   .project-subheading {
      font-weight: 790;
         font-family: Arial, Helvetica, sans-serif;
         color: #0437F2;

   }


.name {
   font-weight: bolder; 
   font-size: 1.30rem;
   font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   text-align: center;
   color: #A9A9A9;
   margin-top: -30px;
   padding: 10.5px;
   border-radius: 10px;
   animation: snake-motion 3s ease-in-out infinite;
}

@media (orientation: landscape) and (max-width: 768px) {
   .name {
      font-size: -2.6rem;
   }
   
}

@media only screen and (max-width: 414px) {
   .name {
      visibility: visible;
      font-size: 0.65rem;
      padding: 7.5px;
      font-weight: bolder;
      margin-top: -2rem;
      font-weight: bolder;
      width: 129%;
      
   }
}

.resume-btn {
background-color: #ff000000;
margin-top: -25.5rem;
margin-bottom: -3.5rem;
   color: #FFC300;
   font-style: oblique;
box-shadow: 1px 1px 1px #A9A9A9;
   border: none;
   border: 2px solid #A9A9A9;
   padding: 15px 30px;
   border-radius: 5px;
   font-size: 0.85rem;
   cursor: pointer;
   transition: box-shadow 0.3s ease;
   font-weight: bolder;
   font-family: Arial, Helvetica, sans-serif;
   display: inline-block;
}

.btn-position {
   margin-top: -0.1rem;
   margin-bottom: -3rem;
}

@media only screen and (max-width: 414px) {
   .btn-position{
      margin-top:10px;
   }
}



@media only screen and (max-width: 414px) {
   .resume-btn {
      font-size: 0.65rem;
      font-weight: bolder;
      visibility: visible;
      border-radius: 10px;
      font-weight: bolder;
      border-radius: 10px;
      font-family: Arial, Helvetica, sans-serif;
      display: inline-block;
      margin: 4px 2px;
      margin-top: -10rem;
      cursor: pointer;
   }
}

.resume-btn:hover {
   box-shadow: 0 0 5px #A9A9A9;
}


@keyframes snake-motion {
   0% {
      transform: translateY(0);
   }

   25% {
      transform: translateY(-10px);
      /* Adjust the height of the upward move */
   }

   50% {
      transform: translateY(0);
   }

   75% {
      transform: translateY(10px);
      /* Adjust the height of the downward move */
   }

   100% {
      transform: translateY(0);
   }
}


.snake-text {
   font-size: 2em;
   font-weight: bold;
   animation: snake-motion 3s ease-in-out infinite;
}


.dancing-text {
   display: inline-block;
   animation: dance 1s infinite;
}

.skills {
   text-align: center;
   margin-top: -10px;
   color: #FFC300;
   font-style: oblique;
   padding: 10px;
   border-radius: 10px;
   font-family: Arial, Helvetica, sans-serif;
      font-weight: bolder;
      font-size: 0.95rem;

}

@media only screen and (max-width: 414px) {
   .skills {
      font-size: 0.60rem;
      font-weight: bolder;
      visibility: visible;
      padding: 15.5px;
      color: #FFC300;
      border-radius: 10px;
      font-weight: bolder;
      margin-top:-3rem;
      margin-bottom: 1rem;
      margin-left: 7.5rem;
   }
}

.skills-distance {
   margin-top: 0px;
}

@media only screen and (orientation: portrait) {
   .skills-distance {
      margin-top: -50px
   }
}


/* Locking orientation on mobile (using viewport and styling suggestions) */
@media only screen and (max-width: 768px) {
   .skills-distance {
      height: 45vh;

      
   }
}

.css-image {
   max-width: 3.50rem;
      height: auto;
      justify-content: center;
      place-content: center;
      margin-bottom: -30px;
      padding: -40px;
      margin: 10px;
      border-radius: 100px;
      margin-left: 1.3rem;
}

.reactnative-name {
   font-weight: bold;
   padding: 13px;
   margin-bottom: 100px; 
   text-align: center;
   font-size: 0.65rem;
   color: #A9A9A9;
   margin-left: -0.1rem;
   font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 414px) {
   .reactnative-name {
      font-size: 0.70rem;
      color: #A9A9A9;
      font-weight: bolder;
      text-align: center;
      margin-top: -10px;
      visibility: visible;
      margin-left: -0.3rem;
      font-weight: bolder;
   }
}

.javascript-name {
   font-weight: bolder;
    padding: -5px;
      font-size: 0.65rem;
      color: #A9A9A9;
      margin-right: -0.1rem;
      margin-top: -50rem;
      font-family: Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 414px) {
   .javascript-name {
      font-size: 0.70rem;
      visibility: visible;
   }
}

@media only screen and (max-width: 414px) {
   .skill-name {
      font-size: 2rem;
      visibility: visible;
   }
}

.css-name {
font-weight: bold;
   padding: 13px;
   margin-top: 50px;
   text-align: center;
   font-size: 0.65rem;
   justify-content: center;
   margin-right: 0.2rem;
   color: #A9A9A9;
}

@media only screen and (max-width: 414px) {
   .css-name {
      font-size: 0.70rem;
      margin-right: 0.5rem;
      visibility: visible;
   }
}

.html-name {
   font-weight: bold;
   padding: 13px;
   margin-top: 50px;
   text-align: center;
   font-size: 0.65rem;
   justify-content: center;
   margin-right: 0.3rem;
   color: #A9A9A9;
   font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 414px) {
   .html-name {
      font-size: 0.70rem;
      margin-left: 2.2rem;
      visibility: visible;
      margin-left: 1.2rem;
   }
}


.react-name {
   font-weight: bold;
   padding: 13px;
   margin-top: 50px;
   text-align: center;
   font-size: 0.65rem;
   justify-content: center;
   margin-right: 0.3rem;
   color: #A9A9A9;
   font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 414px) {
   .react-name {
      font-size: 0.70rem;
      margin-left: 1.1rem;
      visibility: visible;
   }
}

.firebase-name {
   font-weight: bold;
   padding: 13px;
   color: #A9A9A9;
   margin-top: 50px;
   text-align: center;
   font-size: 0.65rem;
   justify-content: center;
   margin-left: 0.3rem;
   font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 414px) {
   .firebase-name {
      font-size: 0.70rem;
      margin-left: 1.1rem;
      visibility: visible;
   }
}


.job-description {
   font-weight:bolder;
      font-size:1.1rem;
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      margin: 30px;
       color: #A9A9A9;
      padding: 15.5px;
      border-radius: 10px;
      margin-top: -1rem;   
}

@media only screen and (max-width: 414px) {
   .job-description {
      font-size: 0.56rem;
         font-weight: bolder;
         margin-top: 0.1rem;
      visibility: visible;
      padding: 4px;
      border-radius: 10px;
      font-weight: bolder;
      margin-left: 2rem;
      min-width: 80%;
      line-height: 1.1rem;
   }
}

.img {
   border-radius: 20px;
   max-width: 10rem;
   height: auto;
   margin-top: -2rem;
}

@media only screen and (max-width: 414px) {
   .img {
      visibility: visible;
      border-radius: 20px;
         max-width: 7.5rem;
         height: auto;
         margin-top: -2rem;
   }
}

.work {
   font-weight: bolder;
      font-size: 0.99rem;
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      color: #FFC300;
      font-style: oblique;
      padding: 11.5px;
      border-radius: 10px;
      margin-top: -0.5rem;
}

@media only screen and (max-width: 414px) {
   .work {
      visibility: visible;
               font-size: 0.65rem;
               font-weight: bolder;
               margin-top: -0.1rem;
               visibility: visible;
               padding: 3.5px;
               border-radius: 10px;
               font-weight: bolder;
            }
         }


.project {
font-weight: bolder;
   font-size: 0.80rem;
   font-family: Arial, Helvetica, sans-serif;
   text-align: center;
   color: #FFC300;
   font-style: oblique;
   /* padding: 12.5px; */
   justify-content: center;
   margin-top: -2rem;

   
}

@media only screen and (max-width: 414px) {
   .project {
      font-size: 0.8rem;
      visibility: visible;
      margin-top: -0.5rem;
      margin-left: -2rem;
   }
}

.link-btn {
         color: #FFC300;
         padding: 11.5px;
         border-radius: 20px;
         cursor: pointer;
         border: 2px solid #A9A9A9;
         font-size: 0.6rem;
         font-family: Arial, Helvetica, sans-serif;
         border-radius: 10px;
         margin-left: -20px;
         margin-top: 1px;
         font-weight: bolder;
}

@media only screen and (max-width: 414px) {
   .link-btn {
      font-size: 0.80rem;
      visibility: visible;
      border: 1.3px solid #A9A9A9;
      margin-left: -5px;
      padding: 6.5px;
      width: 5rem;
      height: 2.1rem;
      font-weight: bolder;
      margin-bottom: -20rem;
      text-align: center;
      place-items: center;
      justify-content: center;
   }
}

.gap {
   gap: 30px;
   margin-right: 10px;
   display: flex;
   margin-top: 1.2rem;
}

@media only screen and (max-width: 414px) {
   .gap {
      gap: 50px;
      margin-top: 1.3em;
      margin-left: -0.4rem;
   }
}

.project-heading {
   font-weight: bolder;
      font-size: 0.70rem;
      font-family: system-ui,
         -apple-system,
         BlinkMacSystemFont,
         'Segoe UI',
         Roboto,
         Oxygen,
         Ubuntu,
         Cantarell,
         'Open Sans',
         'Helvetica Neue',
         sans-serif;
      text-align: center;
      color: #A9A9A9;
      padding: 10.5px;
      border-radius: 10px;
      justify-content: center;
      margin-top: -1rem;
      margin-bottom: -0.8rem;

}

@media only screen and (max-width: 414px) {
   .project-heading {
      font-size: 0.6rem;
      visibility: visible;
      margin-top: -20px;
      margin-bottom: -30px;
      font-weight: bolder;
      margin-left: -3rem;
   }
}

@media (max-width: 768px) {
   .dheading {
      font-size: 1.5em;
      /* Smaller font for smaller screens */
   }

   .sproject-subheading {
      font-size: 0.75em;
      color: #0437F2;
      margin-top: -0.1rem;
      margin-bottom: -0.99rem;
      /* Smaller font for smaller screens */
   }
}

.paragraph-container {
   display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
     /* height: 39rem; */
      margin-top: -2.8rem;
      color:#A9A9A9;
      font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 414px) {
   .paragraph-container {
      margin-top: -1.3rem;
      min-width: 90%;
   }
}

.paragraph {
   text-align: center;
      width: 90%;
      ;
  }

  @media only screen and (max-width: 414px) {
     .paragraph {
      font-size: 0.60rem;
      font-weight: 900;
      min-width: 120%;
      line-height: -1.99rem;
      margin-right: 0.4rem;
      
      

     }
  }

  /*@media only screen and (max-width: 300px) {
     .paragraph {
        margin-top: 5.1rem;
     }
  } */

.bqout {
   display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 20px;

   display: contents;
   color: black;
   text-align: center;
      margin-top: -40px;
      max-width: 10vw;
      font-size: 0.78rem;
      font-weight: 500;
      font-family: system-ui,
         -apple-system,
         BlinkMacSystemFont,
         'Segoe UI',
         Roboto,
         Oxygen,
         Ubuntu,
         Cantarell,
         'Open Sans',
         'Helvetica Neue',
         sans-serif;
}

.card-container {
   display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      margin-top: 25px;
      margin-bottom: 55px;
}

@media only screen and (max-width: 414px) {
   .card-container{
      gap: 10px;
      margin-bottom: 2rem;
      margin-right: 3rem;
   }
}




 @media (orientation: landscape) {
   .card-container {
      margin-top: 8px;
   }
}


.card {
      border: 3.90px solid #A9A9A9;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 180px;
      height: 280px;
}

@media only screen and (max-width: 414px) {
   .card {
      width: 14.5rem;
      height: 20rem;
   }
}

.button-container {
padding: 16px;
   display: flex;
   justify-content: space-between;
}

.button {
   padding: 10px 16px;
      background: green;
      /* Button color */
      color: yellow;
      text-decoration: none;
      /* Remove underline */
      border-radius: 5px;
      /* Rounded corners */
      transition: background 0.3s;
}

.dportrait-mode {
height: 100vh;
   /* Full viewport height */
   width: 100vw;
   /* Full viewport width */
   orientation: portrait;
   /* Force portrait orientation */
}

.dlandscape-mode {
   height: auto;
      /* Full viewport height */
      width: 100%;
      /* Full viewport width */
      orientation: landscape;
   /* Your landscape mode styles here */
}

.text-card {
background-color: #581845;
   border-radius: 1000rem;
   height: 50rem;
   width: 74rem;
   box-shadow: 0px 10px 0px #581845;
   transition: transform 0.1s;
   justify-content: center;
   margin-top: 3rem
}

@media only screen and (max-width: 414px) {
   .text-card {
      visibility: visible;
      font-size: 0.70rem;
      padding: 7.5px;
      font-weight: bolder;
      height: 20rem;
      width: 30rem;
      margin-top: 3rem;
      margin-bottom: 10rem;
      border-radius: 1px;
   }
}

.skills-card-container {
      justify-content: center;
      margin-bottom: 10rem;
      margin-top: 4.7rem;
}

@media only screen and (max-width: 414px) {
   .skills-card-container {
      visibility: visible;
      border-radius: 1px;
      margin-top: 100px;
      
      /* height: 12rem; */
      margin-left: -5rem;
         width: 100%; 
   }
}

.project-card-container {
   background-color: #581845;
      box-shadow: 0px 10px 0px #581845;
      transition: transform 0.1s;
      justify-content: center;
      margin-top: -7rem;
      margin-bottom: 5rem;
}

@media only screen and (max-width: 414px) {
   .project-card-container {
      visibility: visible;
      margin-left: 1rem;
      margin-top: -135px;
      
   }
}

@media only screen and (max-width: 414px) {
   .btn-font {
      visibility: visible;
      text-align: center;
      margin-bottom: -1rem;

   }
}

 only screen and (max-width: 414px) {
   .group-card {
      visibility: visible;
      grid-template-columns: repeat(2, 1fr);
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
   }
}




