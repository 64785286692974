
/* Navbar.css */

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #581845;
    padding: 3rem;
    width: 100%;
}


@media only screen and (max-width: 414px) {
    .navbar {
        font-size: 0.5rem;
        font-weight: bolder;
        visibility: visible;
        font-weight: bolder;
        font-family: Arial, Helvetica, sans-serif;
        height: auto;
        min-width:  50.5%;
        padding: -3rem;
        margin-top: -4.5rem;
    }
}

@media only screen and (orientation: landscape) and (max-width: 767px) {
    .navbar {
        margin-right: 10rem;
    }
}

.contact {
   
            font-style: oblique;
            color: #FFC300;
        
}

.brand h1 {
    color: #A9A9A9;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 
    'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    font-weight: bolder;
    font-style: oblique;
}

@media only screen and (orientation: landscape) and (max-device-width: 768px) {
    .brand h1 {
       font-size: 0.60rem;
       margin-right: -10rem;
    }
}

@media only screen and (max-width: 414px) {
    .brand h1 {
        font-size: 0.50rem;
        font-weight: bolder;
        margin-left: -3rem;
    }
}

@media only screen and (orientation: landscape) and (max-width: 768px) {
  .brand h1 {
    
  }
}

.nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 15px;
}

.nav-links a {
    color: #A9A9A9;
    font-size: 1rem;
    font-weight: bolder;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
}

.menu-icon {
    display: none;
    font-size: 1.3rem;
    color: #A9A9A9;
    cursor: pointer;
    margin-right: -3rem;
    padding: 0.3rem;
}

@media (max-width: 414px) {
    .nav-links {
        display: none;
        flex-direction: column-reverse;
        position: absolute;
        top: 63px;
        left: 1;
        right: 0;
        background-color: #023020;
        width: 50%;
        text-align: center;
        padding-right: 1rem;
        font-size: 0.8rem;
        z-index: 50;
        border-radius: 2rem;
    }

    .nav-links.open {
        display: flex;

    }

    .menu-icon {
        display:flex;
    }
}