body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
    height: 100%;
    overflow:scroll;
    background-color: #581845;
      padding: 0;
      box-sizing: border-box;
}

 @media only screen and (max-width: 414px) {
  body {
    visibility: visible;
    padding: 3rem; 
  }
}
 
@media only screen and (orientation: landscape) and (max-device-width: 768px) {
  body {
    padding: -3rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

