.Card {
background-color: #581845;
    margin-top: -2rem;
    justify-content: center;
    margin-bottom: 10rem;
}



@media only screen and (max-width: 414px) {
    .Card {
        visibility: visible;
        font-size: 0.70rem;
        margin-left: -2rem;
        margin-top: -1.9rem;
    }
}

.form-class {
    margin-top: -70px;
}

.Container {

    display: grid;
    place-items: center;
    margin-top: 1rem;
}

.facebook-logo {
border-radius: 1px;
    max-width: 5em;
    height: auto;
    margin-top: -5px;
    cursor: pointer;
}

.linkedin-logo {
    border-radius: 1px;
    max-width: 5em;
    height: auto;
    margin-top: -5px;
    cursor: pointer;
}
.image-class{
    display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
}

.font-class {
    display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
        margin-top: -5.5rem;
}

@media only screen and (max-width: 414px) {
    .font-class {
       margin-top: -4.8rem;
    }
}

.touch {
    font-size: 0.75rem;
    color: #FFC300;
    font-style: oblique;
        width: 22rem;;
        height: 3rem;
        border-radius: 10px;
        margin-left: 2.3rem;
        font-family:  Arial, Helvetica, sans-serif;
            text-align: center;
            margin-top: 20px;    
}

@media only screen and (max-width: 414px) {
    .touch {
        visibility: visible;
        font-size: 0.70rem;
        font-weight: bolder;
        margin-top: 1.5rem;
        margin-left: 0.1rem;
    
    }
}

.email {
    font-size: 0.65rem;
        color: #0437F2;
        border: 2px solid #0437F2;
        padding: 15.5px;
        border-radius: 10px;
        margin-left: 10rem;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center
}

.form-container {
    width: 400px;
    margin: auto;
    padding: 20px;
    border-radius: 5px;
}

@media only screen and (max-width: 414px) {
    .form-container {
        visibility: visible;
        width:18rem;
            margin: auto;
            padding: 20px;
            border-radius: 5px;
            
        }    
    }


.form-group{
    margin-bottom: 12px;
}
.form-control {
    width: 15rem;
    height: 7.5rem;
    border-radius: 10px;
    border: 3.5px solid #A9A9A9;
    margin-left: 3.0rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
}

@media only screen and (max-width: 414px) {
    .form-control {
        visibility: visible;
        margin-left: 0.3rem;
        height: 12.5rem;
        border: 1.8px solid #A9A9A9;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 100;
    }
}

.social-media {
    font-family: Arial, Helvetica, sans-serif;
    font-size:0.99rem;
    color: #A9A9A9;
    cursor: pointer;
}

@media only screen and (max-width: 414px) {
    .social-media {
        font-size: 0.8rem;
        font-weight: bolder;
    }
}


.input {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    padding: 10px;
    border: 5px solid black;
}

.label {
    width: 15rem;
    height: 2.5rem;
        padding: 10px;
        border: 3.5px solid #A9A9A9;
        border-radius: 10px;
        font-size: 16px;
        margin-left: 3.0rem;     
}

@media only screen and (max-width: 414px) {
    .label {
        visibility: visible;
        margin-left: 0.3rem;
    }
}



.msg-btn{
background-color: #581845;
        margin-top: -6.4rem;
        margin-bottom: -9rem;
        color: #FFC300;
        border: none;
        padding: 10px 20px;
        border-radius: 1rem;
        font-size: 0.70rem;
        margin-left: 5.9rem;
        cursor: pointer;
        margin-top: -0.5rem;
        transition: box-shadow 0.3s ease;
        font-weight: bolder;
        font-family: Arial, Helvetica, sans-serif;
        font-style: oblique;
        border: 3.5px solid #A9A9A9
    }

 @media only screen and (max-width: 414px) {
     .msg-btn {
         visibility: visible;
         margin-left: 4.1rem;
         font-size: 0.50rem;
     }
 }

        input::placeholder {
            opacity: 100;
            font-size: 0.65rem;
            font-weight: bolder;
            font-family: Arial, Helvetica, sans-serif;
            
            /* Adjust opacity (default is 0.5) */
            transition: opacity 0.3s ease;
            /* Transition for placeholder opacity */
        }

   